/* Add your CSS styles here to customize the appearance of the website of XerosRadioNonstop */
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@800&display=swap');

* {
font-family: 'Sofia Sans', sans-serif;
box-sizing: border-box;
font-weight: 800;
}

body {
background: rgb(122,1,254);
background: radial-gradient(circle, rgba(122,1,254,1) 15%, rgba(74,85,255,1) 61%, rgba(38,147,255,1) 81%, rgba(38,147,255,1) 88%);
background-color: #ffffff;
color: #ffffff;
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
font-family: 'Sofia Sans', sans-serif;
font-weight: 800;
text-align: center;
text-decoration: none;
margin: 0;
padding: 0;
}

.container {
max-width: 800px;
}

h1,h2,h3,h4,p,b {
font-weight: 800;
}

img {
width: 100%;
height: auto;
}

.navbar {
color: white;
background-color: #008cff; /* Updated navbar color */
}

.navbar-brand img {
width: 100%;
max-width: 80px; /* Adjust the maximum width as needed */
height: auto;
}

.navbar-toggler {
border: none;
outline: none;
}

.navbar-toggler:focus {
outline: none;
}

.navbar-toggler-icon {
display: none; /* Hide the default Bootstrap icon */
}

.fa-bars {
color: #b703ff; /* Updated navbar toggle icon color */
font-size: 24px;
}

.navbar-nav .nav-link {
color: #fff;
}

/* Nu Live Artwork */
#artwork img {
border-radius: 50%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
} 

/* Nieuws Text */
.NieuwsText {
background-color: #008cff;
text-align: center;
color: white;
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;  
}

/* OnAir Handeler */
.onairhandler {
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* DJs Title */
.djstitle {
color: white;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Privacy Beleid | Text */
.privacybeleidtext {
color: white;
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* XerosRadioNonstop Playlist */
.xerosradioplaylist {
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Main Title Playlist */
.MainTitlePlaylist {
color: white;
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Main Title OverOns */
.MainTitleOverons {
color: white; /* Comma was missing here */
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Main Title Nieuws */
.MainTitleNieuws {
color: white; /* Comma was missing here */
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Main Content */
.content {
color: white; /* Comma was missing here */
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Container */
.container {
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Nieuws */
.Nieuws {
text-align: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* XerosRadioNonstop FADE */
.radioplayerxerosradio {
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
}

/* Iframe */
iframe {
border: none;
margin: 0;
padding: 0;
}

/* Container */
.container {
display: flex;
justify-content: center;
align-items: center;
}

/* Box */
.box {
width: 200px;
text-align: center;
color: black; /* Semicolon was missing here */
text-shadow: 1px 1px 2px rgb(0, 174, 255);
background-color: #ffffff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 20px;
margin: 10px;
}

/* Box | IMG */
.box img {
width: 100px;
height: 100px;
border-radius: 50%;
margin-bottom: 10px;
}

/* Box | h2 */
.box h2 {
color: black; /* Semicolon was missing here */
font-size: 18px;
text-decoration: none;
margin-bottom: 5px;
transition: color 0.3s ease; /* Add transition for color change */
}

/* Box | p */
.box p {
color: black; /* Semicolon was missing here */
font-size: 14px;
text-decoration: none;
transition: color 0.3s ease; /* Add transition for color change */
}

/* Box Hover Effect */
.box h2:hover,
.box p:hover {
color: rgb(0, 174, 255);
}

/* Fade in Animation */
@keyframes fadeIn {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}

/* Smaller Screens */
@media (max-width: 600px) {
.container {
    flex-direction: column; /* Change to column for mobile */
}
.box {
    width: 90%; /* Adjust width for smaller screens */
}
}

/* Current Dj Onair Balloon */
.on-air-balloon {
text-align: center;  
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.226); /* Shadow for the balloon */
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow properties */
background-color: rgb(255, 0, 0);
color: white;
padding: 10px;
border-radius: 20px;
display: inline-block;
position: relative;
-webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none; /* Standard syntax */
}

/* Current Dj Onair Balloon | Before */
.on-air-balloon::before {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -10px;
border-width: 10px;
border-style: solid;
border-color: red transparent transparent transparent;
}

/* Radio player container XerosRadioNonstop */
.radioPlayer {
background-color: #008cff;
border-radius: 10px;
max-width: 400px;
margin: 0 auto;
padding: 15px;
display: flex;
flex-direction: column;
align-items: center;
}

/* Now Playing section XerosRadioNonstop */
#nowPlaying {
margin-top: 10px;
color: #ffffff;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow properties */
display: flex;
flex-direction: row;
align-items: center;
text-align: left;
-webkit-user-select: none; /* Safari */
-ms-user-select: none; /* IE 10 and IE 11 */
user-select: none; /* Standard syntax */
}

/* Album Artwork XerosRadioNonstop */
#albumArtwork {
max-width: 100px;
height: 100px;
width: 100px;
margin-right: 10px;
border-radius: 10px; /* Rounded corners */
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Artist and Title Info XerosRadioNonstop */
.info-container {
flex-grow: 1;
}

#artistInfo, #titleInfo {
font-size: 16px;
color: #fff;
}

/* Player Controls XerosRadioNonstop */
.player-controls {
display: flex;
justify-content: center;
align-items: center;
margin-top: 10px;
}

/* Play/Pause Button XerosRadioNonstop */
#playPauseButton {
font-size: 24px; /* Larger play button XerosRadioNonstop */
color: #fff;
cursor: pointer;
margin: 0 20px;
border: none;
border-radius: 50%; /* Rounded corners for play button XerosRadioNonstop */
padding: 5px;
}

/* Chromecast Button */
#castButton {
font-size: 24px; /* Larger play button XerosRadioNonstop */
color: #fff;
cursor: pointer;
margin: 0 20px;
border: none;
border-radius: 50%; /* Rounded corners for play button XerosRadioNonstop */
padding: 5px;
}

/* Volume Slider */
#volumeSlider {
width: 70%;
}

#cb-cookie-banner {
position: fixed;
bottom: 0;
left: 0;
width: 100%;
z-index: 999;
border-radius: 0;
display: none;
}

/* Center the buttonxerosradiononstop */
.buttonxerosradiomain {
display: flex;
justify-content: center;
align-items: center;
/* Set initial styles for the element */
opacity: 0;
/* Define the animation properties */
animation-name: fadeIn;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;    
}

/* Style for the xerosradiononstopbuttons */
.xerosradiobutton {
padding: 10px 20px;
background-color: #00a8db;
color: white;
border: none;
cursor: pointer;
margin: 5px;
text-decoration: none; /* Remove the default underline for anchor elements */
transition: opacity 0.3s ease; /* Add a fade effect on hover */
text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Hover effect - fade the button */
.xerosradiobutton:hover {
opacity: 0.7;
color:#ad00cf;
text-decoration: none;
}
